<template>
  <a :href="$props.href" class="btn btn-cta mb-2.5 flex justify-between">
    <div class="btn-left">
      <span v-if="$props.label" class="text-pumpkin mb-2.5 text-left">{{ $props.label }}</span>
      <span v-if="$props.content" class="text-black text-left">{{ $props.content }}</span>
    </div>
    <div class="btn-right w-12 h-12 rounded-full bg-green flex items-center justify-center">
      <svg v-if="icon_type === 'mobiliteit'" xmlns="http://www.w3.org/2000/svg" width="20" height="24.044" viewBox="0 0 20 24.044">
        <path id="mobiliteit-logo-white-noborder" d="M19.921,4.9l-1.426-.008s-5.168,7.66-5.192,7.652C13.325,12.573,7.317,5,7.29,4.989L5.138,4.978c-.1,0-.088.123-.088.123L5.031,18.054c0,.655.419,1.126.937,1.053l1.2-.255s.088-.016.088-.143c0,0,.013-6.594.019-10-.01,0,5.581,6.453,5.581,6.453s.421.561.822-.088c0,0,4.784-6.883,4.774-6.893l.028-.046V18.213c-1.786.56-12.725,3.352-12.725,3.352a3.07,3.07,0,0,1-1.592-.079c-.91-.332-1.491-1.081-1.491-2.6V2.517L20,3.027V1.448C14.191.822,10.245.607,10.245.607L1.034-.008A1.132,1.132,0,0,0,0,1.249V19.458c.021,1.842.268,2.876,1.81,3.886a5.7,5.7,0,0,0,4.258.475l.032-.011,5.51-1.695a83.093,83.093,0,0,0,8.254-2.843.158.158,0,0,0,.112-.138v-.289L20,4.992S20.01,4.9,19.921,4.9Z" transform="translate(0 0.008)" fill="#fdfcf9"/>
      </svg>
      <Icons v-else :type="icon_type" :weight="icon_weight" :classes="[icon_font_type, icon_size, 'text-creme_light']"></Icons>
    </div>
  </a>
</template>

<script>
import {defineAsyncComponent} from "vue";

const Icons = defineAsyncComponent(() => import("@/components/Icons"));

export default {
  name: "Contact",
  props: ["href", "label", "content", "type"],
  components: {
    Icons
  },
  data() {
    return {
      icon_type: '',
      icon_weight: null,
      icon_font_type: null,
      icon_size: null
    }
  },
  created() {
    switch (this.$props.type) {
      case 'phone':
        this.icon_type = 'phone';
        this.icon_size = 'text-icon-small';
        break;
      case 'email':
        this.icon_type = 'paper-plane';
        this.icon_size = 'text-icon-small';
        break;
      case 'whatsapp':
        this.icon_type = 'whatsapp';
        this.icon_font_type = 'font-brands';
        this.icon_size = 'text-icon-big';
        break;
      case 'signal':
        this.icon_type = 'comment';
        break;
      case 'sms':
        this.icon_type = 'mobile';
        this.icon_weight = 'light';
        break;
      case 'maps':
        this.icon_type = 'directions';
        break;
      case 'mobiliteit':
        this.icon_type = 'mobiliteit';
    }
  }
}
</script>